import { ApiServiceName } from '../../../config/services';
import { JournalDocumentFilter } from '../../../types/journal';

export class JournalPatchDocumentFilter {
  static readonly type = '[Journal] Patch document filter';
  constructor(
    public params: {
      type: ApiServiceName;
      value: Partial<JournalDocumentFilter>;
    },
  ) {}
}
