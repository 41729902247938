import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CustomsProcedure, JournalStateModel } from '../../../types/journal';
import { JournalPatchDocumentFilter } from './journal.actions';

const defaultState = () => {
  return {
    selectType: 'division',
    customsProcedureCondition: '' as CustomsProcedure,
    trash: false,
    searchCondition: 'outNumberCondition',
  };
};

@State<JournalStateModel>({
  name: 'journal',
  defaults: {
    sd: defaultState(),
    dtim: defaultState(),
  },
})
@Injectable()
export class JournalState {
  @Selector()
  static getStatistics(state: JournalStateModel) {
    return state.sd;
  }

  @Selector()
  static getDtImport(state: JournalStateModel) {
    return state.dtim;
  }

  @Action(JournalPatchDocumentFilter)
  journalPatchDocumentFilter(
    ctx: StateContext<JournalStateModel>,
    action: JournalPatchDocumentFilter,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      [action.params.type]: {
        ...state[action.params.type],
        ...action.params.value,
      },
    });
  }
}
